import { NotificationContextProvider } from "cerulean/src/NotificationContext";
import { Button, TextInput } from "@narmi/design_system";
import PasswordTextInput from "cerulean/src/PasswordTextInput";
// @ts-expect-error move to typescript eventually
import ContextForm from "cerulean/src/ContextForm";
import useFormData from "cerulean/src/useFormData";
import CsrfTokenMiddleware from "cerulean/src/CsrfTokenMiddleware";
import NotificationContext from "cerulean/src/NotificationContext";
import jsonFromDocument from "cerulean/src/JsonFromDocument";
import React from "react";
import styles from "./style.module.css";
import OAuthProviders from "./OAuthProviders";
import { useUserConfiguration } from "../../hooks/useUserConfiguration";

function LoginForm() {
  const { formData, onChange } = useFormData<{
    username?: string;
    password?: string;
  }>({});
  const { sendNotification } = React.useContext(NotificationContext);
  const form = React.useRef<HTMLFormElement>(null);
  const { features } = useUserConfiguration();

  const onBlur = () => {
    if (formData.username) {
      const username = formData.username.trim();
      onChange({ username });
    }
  };
  const staffOAuthProviders = jsonFromDocument("fi_staff_oauth_providers");
  React.useEffect(() => {
    const formErrors = jsonFromDocument("form_non_field_errors", "[]");
    if (formErrors.length) {
      sendNotification({ type: "negative", text: formErrors[0] });
    }
  }, []);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const redirect = urlSearchParams.get("next") || "/";
  const prompt = urlSearchParams.get("prompt");

  const isLocal = jsonFromDocument("institution_is_local");
  const showLoginForm = features.includes("staff_portal_password_login") || isLocal;

  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.authenticationContainer}>
        <div className="mobile-web-extra-space">
          <h1 className="fontWeight--thin margin--bottom--xs">
            Log in to
            <br />
            Narmi Command
          </h1>
          <div className={styles.NarmiOIDCLoginMessage}>
            <span>Narmi employee?</span>
            <Button
              href={`/oidc/narmi_google/redirect?next=${redirect}${prompt ? `&prompt=${prompt}` : ""}`}
              as="a"
              kind="plain"
              type="button"
              style={{
                marginLeft: ".6em",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              Log in here
            </Button>
          </div>
          {staffOAuthProviders.length > 0 && (
            <>
              <OAuthProviders providers={staffOAuthProviders} redirect={redirect} prompt={prompt} />
              {showLoginForm && (
                <div className={styles.OAuthSeparator}>
                  <div></div>
                  <span>OR</span>
                  <div></div>
                </div>
              )}
            </>
          )}
        </div>
        {showLoginForm && (
          <ContextForm
            data={formData}
            onChange={onChange}
            ref={form}
            method="post"
            action={`/login?next=${new URLSearchParams(window.location.search).get("next") || "/"}`}
            autoComplete="off"
          >
            <ContextForm.Field required className={styles.contextFormField}>
              <TextInput name="username" field="username" label="Username" onBlur={onBlur} />
            </ContextForm.Field>
            <ContextForm.Field required className={styles.contextFormField}>
              <PasswordTextInput field="password" />
            </ContextForm.Field>
            <CsrfTokenMiddleware />
            <ContextForm.ActionBar vertical={true}>
              <ContextForm.Action
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                onSubmit={(_: any, e: any) => {
                  e.preventDefault();
                  if (form.current) {
                    form.current.submit();
                  }
                }}
                dangerouslyDisableShowLoading
              >
                <Button as="button" label="Log in" style={{ width: "100%", fontWeight: "400" }} />
              </ContextForm.Action>
              <Button
                as="a"
                href="/password_reset"
                kind="plain"
                type="button"
                label="Forgot your password?"
                style={{ paddingTop: "22px", fontWeight: "400" }}
              />
            </ContextForm.ActionBar>
          </ContextForm>
        )}
      </div>
    </div>
  );
}

export function App() {
  return (
    <NotificationContextProvider>
      <LoginForm />
    </NotificationContextProvider>
  );
}
